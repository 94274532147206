import logo from './logo.svg';
import { Routes, Route } from 'react-router-dom';
import React, { useState} from 'react';
import './App.css';
import ListaCompras from './components/ListaCompras';
import NovaCompra from './components/NovaCompra';
import Home from './components/Home';
import Compra from './components/Compra';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import NovoMercardo from './components/NovoMercado';

function App() {

  const [autheticated, setAutheticated] = useState(false);
  const isAuthenticated = () => {
    
    const storagedToken = localStorage.getItem('token')
    if (storagedToken) {
      const obj = JSON.parse(storagedToken)
      if ((new Date()).getTime() > obj.expiry)  {
        localStorage.removeItem('token');
        console.log('remove')
        return false;
      }
    }
  }

  return (
    <Routes>
      <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
      
      <Route path="/compras" element={<PrivateRoute><ListaCompras/></PrivateRoute>}/>
      
      <Route path="/nova-compra" element={<PrivateRoute><NovaCompra /></PrivateRoute>} />
      <Route path="/compra/:id" element={<PrivateRoute><Compra /></PrivateRoute>} />
      <Route path="/novo-mercado" element={<PrivateRoute><NovoMercardo /></PrivateRoute>} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
