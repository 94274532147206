import React, { useState } from 'react';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-blue-500 p-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <img className="h-8 w-8" src="/logo.svg" alt="Logo" />
            <span className="text-white text-2xl ml-2">MeuApp</span>
          </div>

          {/* Botão Hamburguer (visível apenas em telas pequenas) */}
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>

          {/* Links de navegação (menu) */}
          <div className={`sm:flex sm:items-center ${isOpen ? 'block' : 'hidden'}`}>
            <a href="/" className="text-white px-3 py-2 rounded-md text-sm font-medium">
              Home
            </a>
            <a href="/" className="text-white px-3 py-2 rounded-md text-sm font-medium">
              Sobre
            </a>
            <a href="/" className="text-white px-3 py-2 rounded-md text-sm font-medium">
              Contato
            </a>
          </div>
        </div>
      </div>

      {/* Menu expansível em telas pequenas */}
      {isOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a href="/" className="text-white block px-3 py-2 rounded-md text-base font-medium">
              Home
            </a>
            <a href="/" className="text-white block px-3 py-2 rounded-md text-base font-medium">
              Sobre
            </a>
            <a href="/" className="text-white block px-3 py-2 rounded-md text-base font-medium">
              Contato
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
