import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from './Modal';
import ScannerFullScreen from './ScannerFullScreen';
import './Compra.css';
import { fetchDelete, fetchGet, fetchPost } from '../Api';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {NumericFormat} from 'react-number-format';
import { formatToBRL } from '../utils/formatters';


function Compra() {
    
    const [showRemoveButton, setShowRemoveButton] = useState(null);
    const {id} = useParams();
    const [data, setdData] = useState([]);
    const [barcode, setBarcode] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingProduct, setIsLoadingProduct] = useState(false);
    const [productFound, setProductFound] = useState(false);
    const [productData, setProductData] = useState(null)
    const [enableScanner, setEnableScanner] = useState(false)
    const [subtotal, setSubtotal] = useState(null);
    const [formProductData, setFormProductData] = useState({
        purchase_id: parseInt(id, 10),
        name: 'N/D',
        barcode: barcode,
        price: 0,
        quantity: 1,
        wholesale_price: 0,
        wholesale_quantity: 0,
        purchase_product_id: 0
    })
    
    const navigate  = useNavigate();
    const closeModal = () => setIsModalOpen(false);
    const goToHome = () => {
        navigate('/compras')
    }

    const handleGetProduct = (event) => {
        if (event.keyCode == 13) {
            const ean = event.target.value
            getProduct(ean)
        }
    }

    const getProduct = async (ean) => {
        setIsModalOpen(true);
        setIsLoadingProduct(true);
            try {
                setBarcode(ean)
                setFormProductData(prevData => ({
                    ...prevData,
                    barcode: ean,
                }));

                const response = await fetchGet('/products/'+ean);
                setProductFound(response.ok);
                setProductData(response.body);
                setIsLoadingProduct(false);
            } catch (err) {
                alert('Ocorreu um erro!')
            }
    }

    const getSubtotal = (data) => {
        
        const subTotal = data.products.reduce((acc, product) => acc + (product.price * product.quantity), 0);
        console.log(subTotal)
        setSubtotal(subTotal);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (value) {
            setFormProductData(prevData => ({ 
                ...prevData, 
                [name]: (name != "name") ? parseFloat(value) : value
            }));
        }
    };    

    const fetchData = async () => {
        try {
            const response = await fetchGet('/purchases/'+id)
            const products = response.body
            
            setdData(products);
            getSubtotal(products)

            if (!response.ok) {
                alert('Ocorreu um erroao carregar a lista de compra')
            }

        } catch (error) {

        }
    };
    
    const addProduct = async () => {
        
        try {
            
            const formData = JSON.stringify(formProductData);
            const response = await fetchPost('/purchases/products', formData);
            setIsModalOpen(false);
            fetchData();
        }catch (error) {
            console.log(error)
            alert('Erro')
        }
    }

    let holdTimeout
    const [holding, setHolding] = useState(false);

    const handleMouseDown = (purchase_product_id) => {
        holdTimeout = setTimeout(() => {
            setHolding(true);
            setShowRemoveButton(purchase_product_id)
        }, 1000);
    };

    const removeProduct = async (purchase_product_id) => {
        try {

            const response = await fetchDelete(`/purchases/products/${purchase_product_id}`)
            console.log(response.ok)
            if (response.ok) {
                const updatedProducts = data.products.filter(product => product.purchase_product_id !== purchase_product_id);
                setdData(prevData => ({
                    ...prevData,
                    products: updatedProducts
                }));
                setShowRemoveButton(null);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openScanner = () => {
        setEnableScanner(true);
    }

    const closeScanner = () => {
        setEnableScanner(false)
    }

    const handleMouseUp = () => {
        // Limpa o temporizador se o botão for solto antes do tempo
        clearTimeout(holdTimeout);
        if (holding) {
            setHolding(false);
            console.log('Botão foi segurado e depois solto');
            //setShowRemoveButton(true)
            // Execute qualquer ação de liberação, se necessário
        } else {
            console.log('Botão foi clicado e solto rapidamente');
        }
    };

    const handleDetected = (code) => {
        getProduct(code)
        setEnableScanner(false)
    }

    useEffect(() => {
        fetchData();
    },[])


    return (        
        <div className='overflow-x-auto bg-gray-100 p-4 min-h-screen'>
          
            <div><button onClick={openScanner} className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-2">Scanner</button></div>
            <input 
                type="text" 
                placeholder="Informar manualmente" 
                onKeyDown={handleGetProduct} 
                inputMode='numeric'
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
            ></input>

            <div>
                <h2 className='text-2xl text-gray-600 font-semibold mb-2 mt-2'>Total: {formatToBRL(subtotal)}</h2>
            </div>

            <ScannerFullScreen 
                isOpen={enableScanner} 
                onClick={closeScanner}
                onDetected={handleDetected}
            ></ScannerFullScreen>


            <Modal isOpen={isModalOpen} onClose={closeModal}>
                
                { isLoadingProduct ? (
                    <div>Buscando produto</div>
                ): (
                    <>
                    { productData ? (
                        <img src={productData.image_url} className='imageProductModal'/>
                    ):(<p></p>)}


                    { productFound ? (
                    <p></p>

                    ):( <p>Produto não cadastrado</p>)}

                    {productData && productData.name ? (
                    <p>{productData.name}</p>

                    ):(<><label>{barcode}</label><input type='text' placeholder='Informe o nome' name='name' onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"/></>)}

                    <label>Quantidade</label>
                    <input type='text' placeholder='Quantidade' name='quantity' inputMode='numeric' onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"/>

                    <label>Preço</label>
                    <NumericFormat 
                        placeholder='R$ 0,00' 
                        name='price' 
                        onChange={handleInputChange} 
                        thousandSeparator={true} 
                        decimalScale={2} 
                        fixedDecimalScale={true} 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                    />

                    <label>Quantidade Atacado</label>
                    <input type='text' placeholder='Quantidade Atacado' name='wholesale_quantity' inputMode='numeric' onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"/>

                    <label>Preço Atacado</label>
                    <input type='text' placeholder='Preço Atacado' name='wholesale_price' inputMode='numeric' onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                    <button onClick={addProduct} className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Adicionar</button>
                    </>
            )}
            </Modal>

    {data?.products ? (
        data.products.map((product) => (
            <div>
                
                <div className="bg-white mb-3 p-2 rounded shadow flex items-start" id={`container-product-${product.purchase_product_id}`}>
                    <div className="image-product-container w-[70px]">
                        <img src={product.thumbnail !== 'N/D' ? product.thumbnail : '/notfound.jpg'} className="w-full h-auto" />
                    </div>
    
                    <div 
                        className="productDetailContainer flex-1 ml-4" 
                        onMouseDown={() => handleMouseDown(product.purchase_product_id)} 
                        onMouseUp={() => handleMouseUp()} 
                        onTouchStart={() => handleMouseDown(product.purchase_product_id)} 
                        onTouchEnd={() => handleMouseUp()}
                    >
                        <div className="productTitle line-clamp-2 overflow-hidden text-ellipsis">{product.name} {product.id}</div>
                        <div className="productDetails flex space-x-4">
                            <div className="productDetails"><strong>Preço:</strong> {formatToBRL(product.price)}</div>
                            <div className="productDetails"><strong>Qtd:</strong> {product.quantity}</div>
                    </div>
                </div>
            </div>
                
            {showRemoveButton === product.purchase_product_id ? (
    <div className="flex space-x-4 mb-3 items-center">
        <button 
            onClick={() => removeProduct(product.purchase_product_id)}
            className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
        >
            Remover
        </button>

        
        <button 
            onClick={() => setShowRemoveButton(null)} 
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold h-10 w-10 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
        >

            <XMarkIcon className="h-4 w-4 text-white" /></button>
    </div>
) : (<></>)}
            </div>
            
        ))
    ) : (
        <div>Sem produtos</div>
    )}

    <button 
        onClick={goToHome}
        className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
    >Voltar</button>
</div>
    )
}

export default Compra;