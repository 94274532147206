import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ListaCompras.css';
import { fetchGet } from '../Api';
import Navbar from './Navbar';
import { formatToBRL } from '../utils/formatters';

function ListaCompras() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const navigate = useNavigate();
    const meses = [
        {'01':'Janeiro'},
        {'02':'Fevereiro'},
        {'03':'Março'},
        {'04':'Abril'},
        {'05':'Maio'},
        {'06':'Junho'},
        {'07':'Julho'},
        {'08':'Agosto'},
        {'09':'Setembro'},
        {'10':'Outubro'},
        {'11':'Novembro'},
        {'12':'Dezembro'},
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchPurchases();
                setData(response.body);
            } catch (error) {
                setError(error.message); // Captura o erro
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchPurchases = async (filter) => {
        const filterMonth = filter ? `?filterByMonth=${filter}` : '';
        const response = await fetchGet(`/purchases${filterMonth}`);
        getTotalAmount(response.body); // Passa o body que contém as compras
        //setTotalAmount(response.body);  // Atualizando o total
        
        return response;
    };

    const openPurchase = (id) => {
        navigate(`/compra/${id}`);
    };

    const goToHome = () => {
        navigate('/');
    };

    const handleFilter = async (event) => {
        const response = await fetchPurchases(event.target.value);
        setData(response.body);
    };

    const getTotalAmount = (purchases) => {
        if (Array.isArray(purchases) && purchases.length > 0) {
            const subTotal = purchases.reduce((acc, purchase) => acc + (purchase.total_amount), 0);
            setTotalAmount(subTotal);
        }
    };

    if (loading) return <p>Carregando...</p>;
    if (error) return <p>{`Erro: ${error}`}</p>;

    return (
        <>
            <div className="overflow-x-auto bg-gray-100 p-4 min-h-screen">
                <h1 className="text-gray-600 font-bold text-2xl mb-4 text-center">Compras realizadas</h1>

                <select 
                    onChange={handleFilter}
                    className="block w-full px-3 py-2 mb-3 border border-gray-300 bg-white text-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                    <option value="" hidden>Filtre pelo mês</option>
                    {meses.map((mes, index) => {
                        const [value, label] = Object.entries(mes)[0];
                        return (
                            <option key={index} value={value}>
                                {label}
                            </option>
                        );
                    })}
                </select>

                <div class='bg-red-800 w-1/2 text-center mx-auto rounded' >
                    <h2 className='mb-3 text-center text-white text-base p-1 '>Total: {formatToBRL(totalAmount)}</h2>
                </div>

                {data && data.length > 0 ? (
                    data.map((purchase) => (
                        <div 
                            className="bg-white p-2 text-sm mb-3 rounded shadow text-gray-600 flex items-center justify-between" 
                            key={purchase.id} 
                            onClick={() => openPurchase(purchase.id)}
                        >
                            <div>
                                <strong><h2>{purchase.name}</h2></strong>
                                <p>Data da Compra: {new Date(purchase.created_at).toLocaleDateString('pt-BR')}</p>
                            </div>
                            
                            <div className="text-right">
                                <p><strong>{formatToBRL(purchase.total_amount)}</strong></p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Nenhuma compra encontrada.</p>
                )}

                <button 
                    onClick={goToHome}
                    className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                    Voltar
                </button>
            </div>
        </>
    );
}

export default ListaCompras;
